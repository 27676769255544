import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const AboutPage = () => (
    <Layout>
      <SEO title="About" />
      <section className="about-main">
        <div className="container">
          <div className="intro">
            <p>I'm a creative leader, UI/UX designer and developer based in Sydney, Australia. I am excited by applying creative strategy to innovative and emerging technologies that enhance lives and add value.</p>
          </div>
          <div className="about-content">
              <p>I’m passionate about projects with a deeper meaning that are designed to empower people. I'm a big picture thinker who's not afraid of the details and see the value of empathising with the user while considering all possibilities.</p>
              <p>Working as a UI/UX designer, developer, and graphic designer has built a versatile foundation of visual communication and strategy that adds value for my clients.</p>
              <p>When I’m not creating solutions for work, I like to experience life through my other creative outlets like playing music, taking photos, and brewing tasty IPAs.</p>
              <Link to="/contact" className="button-primary">Let's Chat</Link>
          </div>
        </div>
      </section>
    </Layout>
  )
  
  export default AboutPage